import {createAction, handleActions} from 'redux-actions';
import {generalMenuData} from '../constants/general-menu';
import {reducerMethods} from './redux';

export const convertAgeToString = age => {
  let txt;
  let count = age % 100;

  if (count >= 5 && count <= 20) {
    txt = 'лет';
  } else {
    count %= 10;

    if (count === 1) {
      txt = 'год';
    } else if (count >= 2 && count <= 4) {
      txt = 'года';
    } else {
      txt = 'лет';
    }
  }

  return `${age} ${txt}`;
};

export const getGeneralMenuItems = () => (dispatch, getState) => {
  const {
    auth: {userInfo},
  } = getState();

  if (Boolean(userInfo) === false) {
    return null;
  }
  const {role} = userInfo;

  const menuItems = [];

  const menuStaff = [];
  if (role === 'Manager') {
    menuItems.push(generalMenuData.all_staff);
    menuStaff.push(generalMenuData.volunteers);
    menuStaff.push(generalMenuData.temporary_staff);
  } else if (role === 'Recruter') {
    menuStaff.push(generalMenuData.volunteers);
  }

  if (menuStaff.length > 0) {
    menuItems.push(...menuStaff);
    menuItems.push(generalMenuData.materials);
    menuItems.push(generalMenuData.news);
    menuItems.push(generalMenuData.tests);
    menuItems.push(generalMenuData.interview);
    menuItems.push(generalMenuData.schedule);
    menuItems.push(generalMenuData.teams);
    menuItems.push(generalMenuData.attendance);
  }

  return menuItems;
};

export const replaceAppLoading = createAction('REPLACE_APP_LOADING');

export const defaultState = {
  appLoading: false,
};

export const reducer = handleActions(
  {
    [replaceAppLoading]: reducerMethods.replace('appLoading'),
  },
  defaultState,
);
