export const reducerMethods = {
  add: (state, {payload}) => [...state, payload],
  change: (state, {payload}) => state.map(item => (item.id === payload.id ? payload : item)),
  remove: (state, {payload}) => state.filter(name => name !== payload),
  replace: propName => (state, {payload}) => ({
    ...state,
    [propName]: payload,
  }),
  addByPropName: propName => (state, {payload}) => ({
    ...state,
    [propName]: [...state[propName], ...payload],
  }),
  addById: propName => (state, {payload}) => ({
    ...state,
    [propName]: {
      ...state[propName],
      [payload.id]: payload,
    },
  }),
  identity: (state, {payload}) => payload,
  identityByPropName: propName => (state, {payload}) => ({
    ...state,
    [propName]: payload,
  }),
  mergeObjectByPropName: propName => (state, {payload}) => ({
    ...state,
    [propName]: {
      ...state[propName],
      ...payload,
    },
  }),
  update: (state, {payload}) => ({...state, ...payload}),
};
